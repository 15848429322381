import { Drawer } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import Logo from '@src/assets/images/icon_flute-temp.png';
import FltButton from '@src/components/base/buttons/fltButton/fltButton';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText from '@src/components/base/fltText/fltText';
import { toggelSideBar } from '@src/features/sideBar/sideBarSlice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimarySideBarWrapper } from './primarySidebarStyle';
import SidebarMenuItem, { SidebarItem } from './sidebarItem/sidebarItem';

type PrimarySidebarProps = {
  collapse: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
};

export const PrimarySideBar = ({ collapse, setCollapse }: PrimarySidebarProps) => {
  const themes = useSelector((state: AppState) => state.theme.theme);
  const mode = useSelector((state: AppState) => state.theme.mode);
  const userRelship = useSelector((data: AppState) => data.userRelship.arrayData);
  const groups = useSelector((data: AppState) => data.groups.arrayData);
  const userId = useSelector((state: AppState) => state.auth.userId);
  const [activeItem, setActiveItem] = useState<string>('');
  const [yourTeams, setYourTeams] = useState<SidebarItem[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userRelship && groups && userId) {
      const filteredUserRelshipData = userRelship.filter(
        (data) => data.userId === userId && data.type === 'TEAM'
      );

      // Filter groups based on filteredUserRelshipData
      let filteredGroups = groups.filter((group) =>
        filteredUserRelshipData.some((user) => user.forId === group.id)
      );

      // Sort filteredGroups alphabetically by data.name
      filteredGroups = filteredGroups.sort((a, b) => a.name.localeCompare(b.name));

      // Map the sorted groups to yourTeamsNavItems
      const yourTeamsNavItems = filteredGroups.map((data, index) => {
        return {
          id: `${index + 7}`,
          title: data.avatarInitials ? `${data.avatarInitials} - ${data.name}` : data.name,
          type: 'Teams',
          icon: data.icon ? data.icon : '',
          iconColor: data.color ? data.color : '',
          iconSource: '',
          children: [
            {
              id: `${index + 7}-1`,
              title: 'Initiatives',
              icon: 'MagicWand',
              route: `/groups/teams/${data.id}/initiatives`,
            },
            { id: `${index + 7}-2`, title: 'Pay', icon: 'HandCoins', route: '/' },
            {
              id: `${index + 7}-3`,
              title: 'Get Paid',
              icon: 'HandArrowDown',
              route: '/services/web',
            },
            { id: `${index + 7}-4`, title: 'Reports', icon: 'Folders', route: '/' },
            {
              id: `${index + 7}-5`,
              title: 'Settings',
              icon: 'GearSix',
              route: `/groups/teams/details/${data.id}/general`,
            },
          ],
        };
      });

      setYourTeams(yourTeamsNavItems);
    }
  }, [userRelship, groups, userId]);

  const [visibleSections, setVisibleSections] = useState<{ [key: string]: boolean }>({});
  const handleSectionToggle = (title: string) => {
    setVisibleSections((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };
  const mainMenuSidebarItems: SidebarItem[] = [
    {
      id: '1',
      title: 'Dashboard',
      route: '/dashboard',
      icon: 'SquaresFour',
    },
    {
      id: '2',
      title: 'Inbox',
      route: '/inbox',
      icon: 'Tray',
    },
  ];
  const companyWorkspaceSidebarItems: SidebarItem[] = [
    {
      id: '3',
      title: 'Teams',
      route: '/groups/teams',
      icon: 'Users',
      // iconColor: "rgba(88, 162, 0)",
      // iconBg: "rgba(88, 162, 0, 0.12)",
    },
    {
      id: '4',
      title: 'Initiatives',
      route: '/groups/initiatives',
      icon: 'MagicWand',
      // iconColor: "rgb(36, 167, 0)",
      // iconBg: "rgb(36, 167, 0, 0.12)",
    },
    {
      id: '5',
      title: 'Reports',
      route: '/about',
      icon: 'Folders',
      // iconColor: "rgb(0, 170, 183)",
      // iconBg: "rgb(0, 170, 183,0.12)",
    },
    {
      id: '6',
      title: 'Commerce',
      icon: 'Cube',
      // iconColor: "rgb(255, 21, 180)",
      // iconBg: "rgb(255, 21, 180, 0.12)",
      children: [
        { id: '6-1', title: 'Customers and Vendors', icon: 'UsersThree', route: '/' },
        { id: '6-2', title: 'Products and Services', icon: 'CubeFocus', route: '/' },
        { id: '6-3', title: 'Taxes', icon: 'MoneyWavy', route: '/services/web' },
        { id: '6-4', title: 'Shipping Methods', icon: 'Truck', route: '/' },
        { id: '6-5', title: 'Discounts', icon: 'SealPercent', route: '/' },
      ],
    },
  ];
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (iconName: string) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  useEffect(() => {
    setHoveredIcon(null);
  }, [collapse]);

  const SidebarContent = (
    <>
      <div className="sidebar-header">
        <div className="left-container">
          <img src={Logo} title="flute" alt="flute" width="20px" height="20px" />
          {
            <FltText
              variant="display-large"
              styles={{ color: themes.pages.layout.sidebar.text.secondary }}
            >
              Flute
            </FltText>
          }
        </div>
        <div
          className="right-container"
          onClick={() => {
            dispatch(toggelSideBar());
          }}
          onMouseEnter={() => handleMouseEnter('SidebarLeft')}
          onMouseLeave={handleMouseLeave}
        >
          {
            <FltIcon
              weight={hoveredIcon === 'SidebarSimple' ? 'bold' : 'regular'}
              name="SidebarSimple"
              size={16}
            />
          }
        </div>
      </div>
      <div className="content">
        <div className="create-btn-container">
          <FltButton
            type="primary"
            size="default"
            width="100%"
            rightGlyph={
              <FltIcon weight="regular" name="Plus" size={18} iconColor="content-accentText" />
            }
          >
            {
              <FltText variant="heading-medium" textColor="content-accentText">
                Create
              </FltText>
            }
          </FltButton>
        </div>
        <div className={'divider'}></div>
        <div>
          <div className="side-bar-group-item-container">
            {mainMenuSidebarItems.map((item) => (
              <SidebarMenuItem
                collapsed={collapse}
                key={item.id}
                item={item}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            ))}
          </div>
          <div className={'divider'}></div>
          <div className="side-bar-group-item-container">
            <div
              className="nav-item-group-title-container"
              onMouseEnter={() => handleMouseEnter('Company-Workspace')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSectionToggle('Company-Workspace')}
            >
              <FltText variant="heading-small" styles={{ color: 'inherit' }}>
                Company Workspace
              </FltText>
              {/* {navIcons[!visibleSections["Company-Workspace"] ? "arrow-up" : "arrow-right"]} */}
              <FltIcon
                name={!visibleSections['Company-Workspace'] ? 'CaretDown' : 'CaretRight'}
                weight="fill"
                size={12}
                // color={themes.pages.layout.sidebar.icon.quaternary
                // }
              />
            </div>
            {!visibleSections['Company-Workspace'] &&
              companyWorkspaceSidebarItems.map((item) => (
                <SidebarMenuItem
                  key={item.id}
                  item={item}
                  collapsed={collapse}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              ))}
          </div>
          {/* {collapse && <div className="divider-with-border"></div>} */}
          {/* {collapse && <div className="divider-with-border"></div>} */}
          <div className={'divider'}></div>
          <div className="side-bar-group-item-container">
            <div
              className="nav-item-group-title-container"
              onMouseEnter={() => handleMouseEnter('Your-Teams')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSectionToggle('Your-Teams')}
            >
              <FltText variant="heading-small" styles={{ color: 'inherit' }}>
                Your Teams
              </FltText>
              <FltIcon
                name={!visibleSections['Your-Teams'] ? 'CaretDown' : 'CaretRight'}
                weight="fill"
                size={12}
                // color={themes.pages.layout.sidebar.icon.quaternary}
              />
            </div>
            {!visibleSections['Your-Teams'] &&
              yourTeams.map((item) => (
                <SidebarMenuItem
                  collapsed={collapse}
                  key={item.id}
                  item={item}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              ))}
          </div>
          {/* <div className="divider"></div> */}
        </div>
      </div>
    </>
  );

  const [openDrawerSideBar, setOpenDrawerSideBar] = useState(false);

  const handleMouseEnterOnHoverHadler = () => {
    setOpenDrawerSideBar(true);
  };

  const handleMouseLeaveOnDrawer = () => {
    setOpenDrawerSideBar(false);
  };
  return (
    <PrimarySideBarWrapper
      theme={themes}
      collapsed={collapse}
      openDrawerSideBar={openDrawerSideBar}
      mode={mode}
    >
      {collapse && (
        <div className="side-bar-hover-handler" onMouseEnter={handleMouseEnterOnHoverHadler}></div>
      )}
      {!collapse && <>{SidebarContent}</>}
      <Drawer
        anchor="left"
        open={openDrawerSideBar}
        onClose={() => setOpenDrawerSideBar(false)}
        variant="temporary"
        BackdropProps={{ invisible: true }}
      >
        <PrimarySideBarWrapper
          style={{ width: '220px' }}
          theme={themes}
          collapsed={collapse}
          openDrawerSideBar={openDrawerSideBar}
          onMouseLeave={handleMouseLeaveOnDrawer}
          mode={mode}
        >
          {SidebarContent}
        </PrimarySideBarWrapper>
      </Drawer>
    </PrimarySideBarWrapper>
  );
};
