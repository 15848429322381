import { AppState } from '@src/app/rootReducer';
import FltIconButton from '@src/components/base/buttons/fltIconButton/fltIconButton';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText, { TextVariant } from '@src/components/base/fltText/fltText';
import { ProfileMenu } from '@src/components/composite/profileMenu/profileMenu';
import { toggelSideBar } from '@src/features/sideBar/sideBarSlice';
import { toggleColorMode } from '@src/features/theme/themeSlice';
import { Theme } from '@src/styles/themes/themeInterface';
import { formatReadable } from '@src/utils/common';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFindNameById } from './functions';
import { HeaderProps } from './headerInterface';
import { HeaderWrapper } from './headerStyles';
import { NotificationMenu } from '../notificationMenu/notificationMenu';

export const Header = ({ baseRoute, activeItem }: HeaderProps) => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const { id } = useParams();
  const theme: Theme = useSelector((data: AppState) => data.theme.theme);
  const themeMode = useSelector((data: AppState) => data.theme);
  const isSideCollapse = useSelector((state: AppState) => state.sideBar.collapsed);
  const navigate = useNavigate();
  let type = location.split('/')[2];
  const name = useFindNameById(Number(id), type);
  useEffect(() => {}, [name]);
  const activeItems = activeItem?.split('/');
  const [searchText, setSearchText] = useState('');
  const handleToggleColorMode = () => {
    dispatch(toggleColorMode());
  };

  const [variantName, setVariantName] = useState<TextVariant>('title-medium');
  const [searchWidth, setSearchWidth] = useState('250px');
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);
  const handleMouseEnter = (iconName: string) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setVariantName('title-medium');
        setSearchWidth('200px');
      } else if (window.innerWidth < 1000) {
        setVariantName('heading-small');
        setSearchWidth('210px');
      } else {
        setVariantName('heading-small');
        setSearchWidth('215px');
      }
    };

    // Set the initial variant based on the current window width
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (theme.themeType === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme.themeType]);

  return (
    <HeaderWrapper theme={theme}>
      <div className="left-container">
        {isSideCollapse && (
          <div
            className="side-bar-toggle-btn-container"
            onMouseEnter={() => handleMouseEnter('SidebarRight')}
            onMouseLeave={handleMouseLeave}
          >
            <FltIcon
              weight="regular"
              name="SidebarSimple"
              size={16}
              onClick={() => {
                dispatch(toggelSideBar());
              }}
            />
          </div>
        )}

        {activeItem && (
          <>
            {activeItem.split('/').map((segment, index, arr) => {
              const isLast = index === arr.length - 1;
              return (
                <React.Fragment key={index}>
                  <FltText variant="title-medium" textColor="content-static">
                    {index > 1 && <span className="divider">/</span>}
                  </FltText>
                  <div>
                    <FltText
                      variant={isLast ? 'heading-medium' : 'title-medium'}
                      textColor={isLast ? 'content-primary' : 'content-static'}
                      styles={{
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate(`${baseRoute}`)}
                    >
                      {formatReadable(segment)}
                    </FltText>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>

      <div className="right-container">
        <button className="search-anything-btn">
          <FltIcon name="MagnifyingGlass" weight="regular" color={'var(--grey-60)'} size={14} />
          <FltText variant="title-small" styles={{ color: 'var(--grey-60)', marginRight: '50px' }}>
            Search anything...
          </FltText>
          <p style={{ fontSize: '10px', color: 'var(--grey-60)' }}>
            {'\u2318'} + <span style={{ fontSize: '12px' }}>K</span>
          </p>
        </button>

        <NotificationMenu/>
        {/* <FltIconButton
          size="medium"
          type="primary"
          action="button"
          onClick={() => handleToggleColorMode()}
          name="Moon"
        /> */}
        <ProfileMenu />
      </div>
    </HeaderWrapper>
  );
};
