import { AppState } from '@src/app/rootReducer';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText, { TextColorVariant, TextVariant } from '@src/components/base/fltText/fltText';
import { GroupNotificationOperation, NotificationEventType } from '@src/constants/enum';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';
import { Theme } from '@src/styles/themes/themeInterface';
import { hexWithOpacity } from '@src/utils/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { NotificationGeneratorContainer } from './notficationMessageGeneratorStyle';

type NotificationMessageProps = {
  processedEventMessageData: ProcessedEventMessage;
  textVariant?: TextVariant;
  textColor?: TextColorVariant;
  initiatorNameNone?: boolean;
  iconNone?: boolean;
};

const NotificationMessageGenerator: React.FC<NotificationMessageProps> = ({
  processedEventMessageData,
  textVariant = 'label-small',
  textColor = 'content-tertiary',
  initiatorNameNone = false,
  iconNone = false,
}) => {
  const theme: Theme = useSelector((state: AppState) => state.theme.theme);
  const publishedEvents = useSelector((data: AppState) => data.publishedEvents.arrayData);
  const publishedEventsMap = useSelector((data: AppState) => data.publishedEvents.mapData);
  const notificationData = publishedEventsMap.get(processedEventMessageData.publishedEventId);
  const teamsMap = useSelector((state: AppState) => state.teams.mapData);
  const usersMap = useSelector((state: AppState) => state.users.mapData);
  const currentTeam = teamsMap.get(notificationData.forId);
  const currentUserId = useSelector((state: AppState) => state.auth.userId);

  let processedData = {
    initiatorName: '',
    targetUserName: '',
    teamName: '',
    teamIcon: '',
    teamColor: '',
    targetTeamName: '',
    targetTeamIcon: '',
    targetTeamColor: '',
  };

  const initiatorUser = usersMap.get(notificationData.createdById);
  processedData.initiatorName =
    notificationData.createdById == currentUserId ? 'you' : initiatorUser.firstName;
  if (
    notificationData.eventName === NotificationEventType.TEAM_DEPENDENT_ADDED ||
    notificationData.eventName === NotificationEventType.TEAM_DEPENDENT_REMOVED
  ) {
    const targetedTeam = teamsMap.get(
      (notificationData.templateValues as { targetTeamId: number }).targetTeamId
    );
    processedData.targetTeamName = targetedTeam?.name;
    processedData.targetTeamIcon = targetedTeam?.icon;
    processedData.targetTeamColor = targetedTeam?.color;

    const initiatorTeam = teamsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon;
    processedData.teamColor = initiatorTeam?.color;
  }
  if (
    notificationData.eventName === NotificationEventType.TEAM_MEMBER_ADDED ||
    notificationData.eventName === NotificationEventType.TEAM_MEMBER_REMOVED ||
    notificationData.eventName === NotificationEventType.TEAM_MEMBER_ROLE_UPDATED
  ) {
    const targetUser = usersMap.get(
      (notificationData.templateValues as { targetUserId: number }).targetUserId
    );
    processedData.targetUserName = targetUser?.firstName;

    const initiatorTeam = teamsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon;
    processedData.teamColor = initiatorTeam?.color;
  }

  if (notificationData.eventName === NotificationEventType.TEAM_SETTING_UPDATED) {
    const initiatorTeam = teamsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon;
    processedData.teamColor = initiatorTeam?.color;
  }

  let messageParts: React.ReactNode[] = [];

  switch (notificationData.eventName) {
    case NotificationEventType.TEAM_MEMBER_ADDED:
      messageParts = [
        !initiatorNameNone && (
          <FltText variant={textVariant} textColor={textColor} key="initiator">
            {processedData.initiatorName}{' '}
          </FltText>
        ),
        <FltText variant={textVariant} textColor={textColor} key="text">
          added{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="target">
          {processedData.targetUserName}{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          as a member to{' '}
        </FltText>,
        !iconNone && (
          <FltIcon
            name={processedData.teamIcon}
            bg={true}
            color={textColor == 'content-static' ? hexWithOpacity(processedData.teamColor, 0.5) : processedData.teamColor}
            size={12}
          />
        ),
        <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="team">
          {' '}
          {processedData.teamName}
        </FltText>,
      ].filter(Boolean);
      break;
    case NotificationEventType.TEAM_MEMBER_REMOVED:
      messageParts = [
        !initiatorNameNone && (
          <FltText variant={textVariant} textColor={textColor} key="initiator">
            {processedData.initiatorName}{' '}
          </FltText>
        ),
        <FltText variant={textVariant} textColor={textColor} key="text">
          removed{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="target">
          {processedData.targetUserName}{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          from{' '}
        </FltText>,
        !iconNone && (
          <FltIcon
            name={processedData.teamIcon}
            bg={true}
            color={textColor == 'content-static' ? hexWithOpacity(processedData.teamColor, 0.5) : processedData.teamColor}
            size={12}
          />
        ),
        <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="team">
          {processedData.teamName}{' '}
        </FltText>,
      ].filter(Boolean);
      break;
    case NotificationEventType.TEAM_SETTING_UPDATED:
      messageParts = [
        !initiatorNameNone && (
          <FltText variant={textVariant} textColor={textColor} key="initiator">
            {processedData.initiatorName}{' '}
          </FltText>
        ),
        <FltText variant={textVariant} textColor={textColor} key="text">
          updated{' '}
        </FltText>,
        !iconNone && (
          <FltIcon
            name={processedData.teamIcon}
            bg={true}
            color={textColor == 'content-static' ? hexWithOpacity(processedData.teamColor, 0.5) : processedData.teamColor}
            size={12}
          />
        ),
        <FltText variant={textVariant} textColor={textColor} key="team">
          {processedData.teamName}’s{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="text">
          settings{' '}
        </FltText>,
      ].filter(Boolean);
      break;
    case NotificationEventType.TEAM_DEPENDENT_ADDED:
      messageParts = [
        !initiatorNameNone && (
          <FltText variant={textVariant} textColor={textColor} key="initiator">
            {processedData.initiatorName}{' '}
          </FltText>
        ),
        <FltText variant={textVariant} textColor={textColor} key="text">
          added{' '}
        </FltText>,
        !iconNone && (
          <FltIcon
            name={processedData.teamIcon}
            bg={true}
            color={textColor == 'content-static' ? hexWithOpacity(processedData.teamColor, 0.5) : processedData.teamColor}
            size={12}
          />
        ),
        <FltText variant={textVariant} textColor={textColor} key="team">
          {processedData.teamName}{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          as a{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="relation">
          {(notificationData.templateValues as { action: GroupNotificationOperation }).action ===
          GroupNotificationOperation.GROUP_CHILD_ADD
            ? 'child'
            : 'parent'}{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          to{' '}
        </FltText>,
        !iconNone && (
          <FltIcon
            name={processedData.targetTeamIcon}
            bg={true}
            color={textColor == 'content-static' ? hexWithOpacity(processedData.targetTeamColor, 0.5) : processedData.targetTeamColor}
            size={12}
          />
        ),
        <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="targetTeam">
          {processedData.targetTeamName} team
        </FltText>,
      ].filter(Boolean);
      break;
    case NotificationEventType.TEAM_DEPENDENT_REMOVED:
      messageParts = [
        !initiatorNameNone && (
          <FltText variant={textVariant} textColor={textColor} key="initiator">
            {processedData.initiatorName}{' '}
          </FltText>
        ),
        <FltText variant={textVariant} textColor={textColor} key="text">
          removed{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="team">
          {processedData.teamName}{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          as a{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="relation">
          {(notificationData.templateValues as { action: GroupNotificationOperation }).action ===
          GroupNotificationOperation.GROUP_CHILD_DELETE
            ? 'child'
            : 'parent'}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          of{' '}
        </FltText>,
        !iconNone && (
          <FltIcon
            name={processedData.targetTeamIcon}
            bg={true}
            color={textColor == 'content-static' ? hexWithOpacity(processedData.targetTeamColor, 0.5) : processedData.targetTeamColor}
            size={12}
          />
        ),
        <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="targetTeam">
          {processedData.targetTeamName} team
        </FltText>,
      ].filter(Boolean);
      break;
    case NotificationEventType.TEAM_MEMBER_ROLE_UPDATED:
      messageParts = [
        !initiatorNameNone && (
          <FltText variant={textVariant} textColor={textColor} key="initiator">
            {processedData.initiatorName}{' '}
          </FltText>
        ),
        <FltText variant={textVariant} textColor={textColor} key="text">
          updated{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="target">
          {processedData.targetUserName}’s{' '}
        </FltText>,
        <FltText variant={textVariant} textColor={textColor} key="text">
          role to{' '}
        </FltText>,
        !iconNone && (
          <FltIcon name="Users" size={14} iconColor="content-tertiary" />
        ),
        <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="role">
            {(notificationData.templateValues as { role: string }).role}
          </FltText>
      ].filter(Boolean);
      break;
    default:
      messageParts = [<span key="default">No message available</span>];
  }

  return (
    <NotificationGeneratorContainer theme={theme}>
      <FltText
        variant={textVariant}
        textColor={textColor}
        isEllipsis={true}
        styles={{ display: 'flex', alignItems: 'center', gap: '3px' }}
      >
        {messageParts}
      </FltText>
    </NotificationGeneratorContainer>
  );
};

export default NotificationMessageGenerator;
