import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const NotificationGeneratorContainer = styled.div<{ theme: Theme }>`
  width: 100%;
  .role-container {
    display: flex;
    padding: 2px 4px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
  }
`;
