// Define the colors object with explicit typing
import { Theme } from '@src/styles/themes/themeInterface';
import styled, { css, CSSObject, RuleSet } from 'styled-components';
import { ButtonProps, ChildLayout, Shape, Size, Type } from './fltButtonInterface';

const dropdownBtnStyles = css<{ theme: Theme }>`
  display: flex;
  padding: var(--spacing-0x, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.fltButton.dropdown.border.primary};
  background: ${({ theme }) => theme.fltButton.dropdown.background.primary};
  &:hover {
    border-color: ${({ theme }) => theme.fltButton.dropdown.border.hover.primary};
    background: ${({ theme }) => theme.fltButton.dropdown.background.hover.primary};
  }

  &:active {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.fltButton.dropdown.border.active.primary};
    background: ${({ theme }) => theme.fltButton.dropdown.background.active.primary};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.dropdown-open {
    background: ${({ theme }) => theme.fltButton.dropdown.background.opened.primary};
  }
`;

// Define the sizes object with explicit typing
const sizes: { [key in Size]: RuleSet<object> } = {
  default: css`
    padding: var(--spacing-1x, 8px) var(--spacing-1-5x, 12px);
    font-size: 13px;
    height: 32px;
    svg {
      font-size: 14px;
    }
  `,
  small: css`
    padding: var(--spacing-0-50x, 4px) var(--spacing-1x, 8px);
    font-size: 12px;
    height: 24px;
    svg {
      font-size: 14px;
    }
  `,
};

const types: { [key in Type]: RuleSet<{ theme: Theme }> } = {
  primary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.surface.button.primary.default};
    color: ${({ theme }) => theme.content.button.primary.default};
    box-shadow: ${({ theme }) =>
      theme.themeType == 'light'
        ? `0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 2px 6px 0px rgba(2, 78, 174, 0.25), 0px 0px 0px 1px var(--brand-blue-70, #004091) inset, 0px 2px 0px 0px var(--brand-blue-50, #306FBE) inset`
        : `0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 2px 6px 0px rgba(2, 78, 174, 0.25), 0px 0px 0px 1px var(--brand-blue-70, #004091) inset, 0px 2px 0px 0px var(--brand-blue-50, #306FBE) inset`};
    &:hover {
      color: ${({ theme }) => theme.content.button.primary.hover};
      background-color: ${({ theme }) => theme.surface.button.primary.hover};
      box-shadow: ${({ theme }) =>
        theme.themeType == 'light'
          ? `0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 2px 6px 0px rgba(2, 78, 174, 0.25), 0px 0px 0px 1px var(--brand-blue-60, #024EAE) inset, 0px 2px 0px 0px var(--brand-blue-40, #5E90CE) inset`
          : ` 0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 2px 6px 0px rgba(2, 78, 174, 0.25), 0px 0px 0px 1px var(--brand-blue-60, #024EAE) inset, 0px 2px 0px 0px var(--brand-blue-40, #5E90CE) inset`};
    }

    &:focus {
      background-color: ${({ theme }) => theme.surface.button.primary.default};
      color: ${({ theme }) => theme.content.button.primary.default};
      box-shadow: ${({ theme }) => theme.boxshadow.focused};
    }

    &:active {
      color: ${({ theme }) => theme.content.button.primary.active};
      background-color: ${({ theme }) => theme.surface.button.primary.active};

      box-shadow: ${({ theme }) =>
        theme.themeType == 'light'
          ? `0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 2px 6px 0px rgba(2, 78, 174, 0.25), 0px 0px 0px 1px var(--brand-blue-80, #002655) inset, 0px 2px 0px 0px var(--brand-blue-50, #306FBE) inset`
          : ` 0px 0px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 2px 6px 0px rgba(2, 78, 174, 0.25), 0px 0px 0px 1px var(--brand-blue-80, #002655) inset, 0px 2px 0px 0px var(--brand-blue-50, #306FBE) inset`};
    }

    &:disabled {
      color: ${({ theme }) => theme.content.button.primary.disabled};
      background-color: ${({ theme }) => theme.surface.button.primary.disabled};
      box-shadow: ${({ theme }) => (theme.themeType == 'light' ? `` : ``)};
    }
  `,
  secondary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.surface.button.secondary.default};
    color: ${({ theme }) => theme.content.button.secondary.default};
    border: ${({ theme }) => `1px solid ${theme.border.secondary}`};
    box-shadow: ${({ theme }) => theme.boxshadow.small};

    &:hover {
      color: ${({ theme }) => theme.content.button.secondary.active};
      border: ${({ theme }) => `1px solid ${theme.border.primary}`};
      background-color: ${({ theme }) => theme.surface.button.secondary.default};
      box-shadow: ${({ theme }) => theme.boxshadow.small};
    }
    &:focus {
      background-color: ${({ theme }) => theme.surface.button.secondary.default};
      color: ${({ theme }) => theme.content.button.secondary.default};
      border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
      box-shadow: ${({ theme }) => theme.boxshadow.focused};
    }
    &:active {
      color: ${({ theme }) => theme.content.button.secondary.active};
      border: ${({ theme }) => `1px solid ${theme.border.primary}`};
      background-color: ${({ theme }) => theme.surface.button.secondary.active};
      box-shadow: ${({ theme }) => theme.boxshadow.small};
    }

    &:disabled {
      color: ${({ theme }) => theme.content.button.secondary.disabled};
      background-color: ${({ theme }) => theme.surface.button.secondary.default};
      border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
      box-shadow: ${({ theme }) => theme.boxshadow.small};
    }
  `,
  tertiary: css<{ theme: Theme }>`
    background-color: ${({ theme }) => theme.surface.button.tertiary.default};
    color: ${({ theme }) => theme.content.button.secondary.default};
    // border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
    box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.primary}`};
    border: none;

    &:hover {
      box-shadow: ${({ theme }) => `${theme.fltButton.boxShadow.hover.secondary} 0 0 0 1px`};
      background-color: ${({ theme }) => theme.surface.button.tertiary.hover};
    }
    &:active {
      background-color: ${({ theme }) => theme.surface.button.tertiary.active};
    }
    &:focus {
      background-color: ${({ theme }) => theme.surface.button.tertiary.active};
      color: ${({ theme }) => theme.content.button.tertiary.active};
      box-shadow: 0px 0px 0px 2px rgba(2, 78, 174, 0.2);
    }
    &:disabled {
      background-color: ${({ theme }) => theme.surface.button.tertiary.disabled};
    }
  `,
};

const layouts: { [key in ChildLayout]: RuleSet<object> } = {
  default: css`
    justify-content: center;
  `,
  'space-between': css`
    justify-content: space-between;
  `,
};

const shapeStyles: { [key in Shape]: RuleSet<object> } = {
  circle: css`
    border-radius: 50%;
    width: 32px;
    padding: 0;
  `,
  rounded: css`
    border-radius: 20px;
  `,
  rectangle: css`
    border-radius: 6px;
  `,
};

export const FltButtonWrapper = styled.button<ButtonProps>`
  width: ${({ width }) => (width ? width : '')};
  appearance: none;
  padding: 0px;
  margin: 0px;
  display: inline-flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  transition: 150ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  z-index: 0;
  text-wrap: nowrap;
  border: 1px solid transparent;
  ${({ shape = 'rectangle' }) => shapeStyles[shape]}
  ${({ size = 'default' }) => sizes[size]}
  ${({ btnType = 'primary' }) => types[btnType]}
  ${({ styles }) => styles && css(styles as CSSObject)}


  &:focus {
    outline: none;
  }

  .btn-content {
    display: flex;
    /* display: grid; */
    grid-auto-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: relative;
    z-index: 0;
    gap: 6px;
    ${({ childLayout = 'default' }) => layouts[childLayout]}
  }
`;

//  /* ${({ btnFor = 'normal-btn', btnType = 'primary', appearance = 'filled' }) =>
//     getButtonStyles(btnType, appearance, btnFor)} */
