import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const NotificationMenuWrapper = styled.div<{ theme: Theme }>`
  .header {
    display: flex;
    padding: var(--spacing-1-5x, 12px) var(--spacing-2x, 16px);
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
  }
  .toggle-button-container {
    display: flex;
    padding: var(--spacing-1-5x, 12px) var(--spacing-1-5x, 12px) var(--spacing-0x, 0px)
      var(--spacing-1-5x, 12px);
    align-items: center;
    gap: 4px;
  }
  .content-container-heading {
    display: flex;
    padding: var(--spacing-1x, 4px) var(--spacing-2x, 16px) var(--spacing-0x, 4px)
      var(--spacing-2x, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .content-container {
    display: flex;
    padding: var(--spacing-0-50x, 4px);
    gap: var(--spacing-0-50x, 4px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: ${({ theme }) => `1px solid ${theme.border.tertiary}`};

    .card-details-container {
      display: flex;
      align-items: center;
      gap: var(--spacing-2x, 16px);
      align-self: stretch;

      .right-card-container {
        display: flex;
        align-items: flex-start;
        flex: 1 0 0;
        .first-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-0-50x, 4px);
          flex: 1 0 0;
          width: 219px;
          .card-primary-content {
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
          }
          .card-secondary-content {
            display: flex;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;
            align-self: stretch;
          }
        }
        .second-container {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
    .card-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2x, 16px);
      padding: var(--spacing-1-5x, 12px);
      align-items: flex-start;
      flex: 1 0 0;
      border-radius: 6px;
      cursor: pointer;
      user-select: none;
      width: 100%;

      .card {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-2x, 16px);
        align-self: stretch;
      }
      .button-container {
        display: flex;
        padding-left: var(--spacing-6x, 48px);
        align-items: center;
        gap: 8px;
      }
    }
    .card-container:hover{
      background:  ${({ theme }) => theme.opacity.button.hover}; 
    }

    .no-data-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .no-data-container {
      display: flex;
      width: 292px;
      padding: var(--spacing-2x, 16px) 0px;
      flex-direction: column;
      align-items: center;
      gap: 13px;
      .no-data-text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-1x, 8px);
        align-self: stretch;
      }
    }
  }

  .footer-conatiner {
    display: flex;
    padding: var(--spacing-1-5x, 12px) var(--spacing-2x, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
  .avator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: ${({ theme }) => theme.surface.background.warning};
  }
  .avator-icon-badge-container {
    display: flex;
    width: var(--spacing-2x, 16px);
    height: var(--spacing-2x, 16px);
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    background: ${({ theme }) => theme.surface.background.secondary};
    gap: 8px;
  }
  .blue-badge {
    display: flex;
    width: 6px;
    height: 6px;
    min-width: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--spacing-10x, 80px);
    background: var(--brand-blue-60, #024eae);
  }
`;
